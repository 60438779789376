<template>
  <div id="test-container">
    <el-button class="confirm" @click="handleConnect" round>确认</el-button>
    <div class="content">
      <textarea name="process" id="processContent"></textarea>
      <div>
        姓名：
        <div id="name"></div>
        身份证号：
        <div id="number"></div>
      </div>
      <div class="ID-card card-back">
        <p id="department"></p>
        <p id="expiry"></p>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, nextTick, onMounted } from "vue";
export default {
  setup() {
    const connectAddress = ref("127.0.0.1:30004");
    const processContent = ref(null);
    const name = ref(null);
    const number = ref(null);
    onMounted(() => {
      nextTick(() => {
        processContent.value = document.getElementById("processContent");
        name.value = document.getElementById("name");
        number.value = document.getElementById("number");
      });
    });
    function cleanMsg() {
      processContent.value.innerHTML = "";
      name.value.innerHTML = "";
      number.value.innerHTML = "";
    }
    function hex2a(hex) {
      let str_list = "";
      for (let i = 0; i < hex.length && hex.substr(i, 2) !== "00"; i += 2) {
        const a = hex.charCodeAt(i);
        const b = hex.charCodeAt(i + 1);
        const c = b * 256 + a;
        str_list += String.fromCharCode(c);
      }
      return str_list.toString();
    }

    function handleConnect() {
      const webUrl = "ws://" + connectAddress.value + "/ws";
      const ws = new WebSocket(webUrl);
      ws.onopen = function () {
        let szhelp =
          "websocket连接成功，url[" +
          webUrl +
          "]，读卡器上放置身份证后websocket会自动接收身份证数据，如需手动操作请调用WS_ReadInfo()函数\r\n\r\n";
        szhelp += "支持被动接收和主动请求两种方式\r\n";
        szhelp +=
          "被动接收:当读卡器刷卡成功后会推送身份证信息到websocket，websocket直接显示即可\r\n";
        szhelp +=
          "主动请求:支持网页端主动向服务器请求对应的消息。可查看<WS_ReadInfo><WS_GetASN><WS_GetBCardNo>这三个接口";
        console.log(szhelp);
      };
      ws.onclose = function () {
        console.log("websocket已断开");
      };
      ws.onmessage = function (messageEvent) {
        const jsonobject = JSON.parse(messageEvent.data);
        if (jsonobject.Ret == 0) {
          if (jsonobject.Cmd == 10001) {
            cleanMsg();
            console.log("websocket 协议 读取身份证成功");
            const szparam = JSON.parse(window.atob(jsonobject.UserParam));
            name.value.innerHTML = hex2a(window.atob(szparam.CardInfo.Name));
            number.value.innerHTML = hex2a(window.atob(szparam.CardInfo.No));
          } else if (jsonobject.Cmd == 30401) {
            const szparam = JSON.parse(window.atob(jsonobject.UserParam));
            console.log("websocket 协议 读取A卡SN成功：" + szparam.SN);
          } else if (jsonobject.Cmd == 20401) {
            const szparam = JSON.parse(window.atob(jsonobject.UserParam));
            console.log("websocket 协议 读取身份证卡片SN成功：" + szparam.SN);
          } else if (jsonobject.Cmd == 20511) {
            const szparam = JSON.parse(window.atob(jsonobject.UserParam));
            console.log("websocket 协议 读卡器唯一号：" + szparam.SN);
          } else if (jsonobject.Cmd == 1000) {
            const szparam = JSON.parse(window.atob(jsonobject.UserParam));
            if (szparam.State == 0) {
              console.log("读卡器已被拔出");
            } else {
              console.log("读卡器已插入");
            }
          } else {
            console.log("websocket 协议调用失败，原因：" + jsonobject.ErrInfo);
          }
        }
      };
    }
    return { handleConnect };
  },
};
</script>

<style lang="scss" scoped>
#test-container {
  .content {
    display: flex;
    justify-content: space-between;
    #processContent {
      width: 600px;
      height: 400px;
    }
  }
}
</style>
